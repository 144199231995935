import country_iso2 from "./Country_iso.json"
import cinetPayMethodePaymentList from "./cinetPayMethodePaymentList.json"
import logo from "../assets/image/logo1.png";
import visaMasterCard from "../assets/image/Visa-Mastercard.jpg";
import mobileMoney from "../assets/image/mobileMoney.png";

export const IMAGE = {
    logo: logo,
    visaMasterCard: visaMasterCard,
    mobileMoney: mobileMoney,
}

export const EXIT_PAGE = `${process.env.REACT_APP_API_URL}/form_abonnement.php`
export const PROFIL_PAGE_PATH = `${process.env.REACT_APP_API_URL}/profil.php`

export const COUNTRY_ISO2 = country_iso2;

export const CONTACT = {
    tel: '+223 77 77 78 78',
    email: 'icpsarlmali@yahoo.com',
}

export const CINETPAY_METHODE_PAYMENT_LIST = cinetPayMethodePaymentList;

export const MONTANT_ABONNEMENT = 36500;