import axios from "axios";
import { EXIT_PAGE, MONTANT_ABONNEMENT } from "../constant/constant";

/**
 * Interface for the API response
 */
interface REQUEST_T {
    /**
     * API response ID
     */
    api_response_id: string;
    /**
     * Response code
     */
    code: number;
    /**
     * Response data
     */
    data: {
        /**
         * Payment token
         */
        payment_token: string;
        /**
         * Payment URL
         */
        payment_url: string;
    };
    /**
     * Response description
     */
    description: string;
    /**
     * Response message
     */
    message: string;
}

/**
 * Get payment token from CinetPay API
 * 
 * @param {number} idClient - Client ID
 * @param {string} nom - Client first name
 * @param {string} prenom - Client last name
 * @param {string} email - Client email
 * @param {string} tel - Client phone number
 * @param {string} adresse - Client address
 * @param {string} city - Client city
 * @param {string} country - Client country code2 like ML, FR
 * @param {string} state - Client state code2 like ML, FR
 * @param {number} codeZIP - Client ZIP code
 * @param {string} [transaction_id=Math.floor(Math.random() * 100000000).toString()] - Transaction ID (optional)
 * 
 * @returns {Promise<REQUEST_T>} - Promise resolving to the API response
 * 
 * @example
 * const paymentToken = await getPaimentToken(
 *   123,
 *   'John',
 *   'Doe',
 *   'johndoe@example.com',
 *   '+22389554754',
 *   'kalanban koura',
 *   'Bamako',
 *   'ML',
 *   'ML',
 *   10001
 * );
 * console.log(paymentToken.payment_token); // Output: "payment_token_123"
 */
export const getPaimentToken = (
    idClient: number,
    nom: string,
    prenom: string,
    email: string,
    tel: string,
    adresse: string,
    city: string,
    country: string,
    state: string,
    codeZIP: number,
    transaction_id: string = Math.floor(Math.random() * 100000000).toString(),
    user_id: number,
    channels: "MOBILE_MONEY" | "CREDIT_CARD"
): Promise<REQUEST_T> => {
    return new Promise((resolve, reject) => {
        var data = {
            apikey: process.env.REACT_APP_CINETPAY_APIKEY,
            site_id: process.env.REACT_APP_CINETPAY_SITEID,
            transaction_id: transaction_id, //
            amount: MONTANT_ABONNEMENT,
            currency: "XOF",
            alternative_currency: "USD",
            description: "Paiement d'abonnement",
            customer_id: idClient,
            customer_name: nom,
            customer_surname: prenom,
            customer_email: email,
            customer_phone_number: tel,
            customer_address: adresse,
            customer_city: city,
            customer_country: country,
            customer_state: state,
            customer_zip_code: codeZIP,
            notify_url: `${process.env.REACT_APP_API_URL}/api/paiement/notify.php?inscrits_id=${idClient}`,
            return_url: `${EXIT_PAGE}?tmp=${user_id}`,
            channels: channels,
            metadata: idClient,
            lang: "FR",
            invoice_data: {
                "Montant de l'abonnement": `${MONTANT_ABONNEMENT.toLocaleString()} XOF`,
            }
        };

        axios.postForm(
            `${process.env.REACT_APP_CINETPAY_URL}`,
            data
        ).then(
            res => {
                console.log('====================================');
                console.table(res.data);
                console.log('====================================');

                const result: REQUEST_T = res.data;

                resolve(result);
            }
        ).catch(
            err => {
                console.error('====================================');
                console.table(err);
                console.error('====================================');

                reject(err);
            }
        )
    })
}